import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';
import { fonts } from '../styles/variables'
import { LilaHeart } from '../constants/lilaIcons'


const LilaHeartWrapper = styled.div`
display:flex;
justify-content:center;
margin-bottom:6%;

svg{
width:64px;

  @media screen and (max-width:720px){
width:52px;
  }
}


`


const LilaThankYou = styled(Container)`
margin-top:25%;



`

const H1 = styled.div`
font-size:2.2rem;
display:flex;
justify-content:center;
text-align:center;

  @media screen and (max-width:720px){
font-size:1.7rem;
   padding-left:10%;
  padding-right:10%;}
`

const LilaExpressNote = styled.div`
font-family:${fonts.bcHairLine};
width:480px;
margin-top:3%;
margin-left:auto;
margin-right:auto;

  @media screen and (max-width:720px){
width:100%;
  padding-left:10%;
  padding-right:10%;
  text-align:center;
  }
`

const OrderSuccess = ({pathContext: { locale }}) =>  (
  <IndexLayout locale={locale}>
    <Page>

      <LilaThankYou>
        <LilaHeartWrapper><LilaHeart/></LilaHeartWrapper>
        <H1><FormattedMessage id={'success_0'} /> </H1>
<LilaExpressNote><FormattedMessage id={'success_1'} /></LilaExpressNote>
      </LilaThankYou>
    </Page>
  </IndexLayout>
)

export default OrderSuccess
